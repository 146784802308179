import axios from 'axios'
window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

if(window.matchMedia) {
    window.matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change',({ matches }) =>
            localStorage.setItem('color-scheme', matches ? 'dark' : 'light')
        )

    if (window.matchMedia('(prefers-color-scheme: dark)').matches && !localStorage.getItem('color-scheme')) {
        localStorage.setItem('color-scheme', 'dark')
    }

    // document.querySelector('html, body').classList.add(localStorage.getItem('color-scheme'))
}
